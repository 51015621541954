@if (model) {
  <div>
    <!-- Header -->
    <oex-headline
      #headline
      [model]="model"
      (onWatch)="toggleWatch()"
      (onStar)="toggleStar()"
      (onInfo)="onShowInfoClicked($event)"
      (onDownload)="download($event)"
    >
      <!--<menu-button
      ngProjectAs="title"
      class="transparent"
      icon="assets/img/edit-outline.svg"
      [items]="getActions()"
      (select)="onAction($event)"
    ></menu-button>-->
    </oex-headline>
    <!-- Tabs -->
    <oex-tabs [currentTab]="currentTab" [tabs]="getTabs()" [useQuery]="true" (currentTabChange)="onTabChanged($event)"></oex-tabs>
    <!-- TODO: ***** Temp markdown test -->
    <!--<div class="test">
    <div class="mp-markdown" [innerHTML]="getTestingMarkdown1()"></div>
    <div class="mp-markdown" [innerHTML]="getTestingMarkdown2()"></div>
  </div>-->
    <div class="details">
      <div [hidden]="currentTab === tabs.info">
        <!-- Online demo -->
        @if (currentTab === tabs.details && model.DemoURL) {
          <oex-wide-button text="View" [href]="model.DemoURL">
            This app has a demo
          </oex-wide-button>
        }

        <!-- Tags -->
        @if (currentTab === tabs.details && model.Tag && !pd.enabled) {
          <div class="tag-list">
            @for (tag of model.Tag.toString().split(','); track tag) {
              <a class="lnk-tag" [hidden]="!tag"
                 routerLink="/" [queryParams]="{tag: encodeTag(tag)}">{{ tag }}</a>
            }
          </div>
        }

        <!-- Short description -->
        @if (model.Description && currentTab === tabs.details) {
          <div
            class="mp-pkg-desc pkg-short-desc">{{ model.Description }}
          </div>
        }
        <!-- One review -->
        <!-- <oex-review-card
        *ngIf="model?.Reviews[0] && currentTab === tabs.details"
        [hidden]="currentTab !== tabs.details"
        [review]="model?.Reviews[0]"
      ></oex-review-card>-->
        <!-- Full description -->
        @if (currentTab === tabs.details && model.FullDescriptionMd) {
          <oex-card
            [minHeight]="400"
            [collapsible]="false"
            [header]="false"
          >
            <!-- Whats new -->
            @if (model.lastNotes) {
              <div class="whats-new" [hidden]="currentTab !== tabs.details">
                <h3>What's new in this version</h3>
                <div [innerHtml]="model.lastNotes" class="mp-markdown"></div>
              </div>
            }
            <div #descriptionMD [innerHtml]="model.FullDescriptionMd"
                 class="mp-pkg-desc mp-markdown"></div>
          </oex-card>
        }
        <media-list [canEdit]="!model.Published && canEdit()" [hidden]="currentTab !== tabs.screenshots"
                    [video]="model.VideoURLs || []" [media]="model.Screenshots || []"
                    [packageId]="parseInt(model._id)"></media-list>
        <!-- Video -->
        @if (currentTab === tabs.videos) {
          @for (vid of model.VideoURLs; track trackByIndex(idx, vid); let idx = $index) {
            <div class="video">
              <a [href]="getVideoUrl(idx)" target="_blank" class="mp-link">{{ this.model.VideoURLs[idx].url }}</a>
              <iframe
                class="video optanon-category-C0004"
                [attr.data-src]="getVideoUrl(idx)"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style="width: 100%; height: 400px;"
              ></iframe>
            </div>
          }
        }
        <!-- Releases -->
        @if (currentTab === tabs.history) {
          <oex-version-history
            [versions]="model.Versions || []"
            [canEdit]="model.UserID.login === auth.getUser()"
            [published]="!!model.Published"
            [packageModel]="model"
            (onChange)="onVersionsChanged($event)"
          ></oex-version-history>
        }
        <!-- Analytics tab -->
        @if (currentTab === tabs.analytics) {
          <div>
            <oex-pkg-downloads [model]="model" [id]="parseInt(model._id)"
                               [total]="model.DownloadsCount || 0"></oex-pkg-downloads>
          </div>
        }
        <!-- Issues tab -->
        <div [hidden]="currentTab !== tabs.issues" class="mp-issues">
          <oex-wide-button class="btn-issues tablet-vertical" text="Post an issue on GitHub"
                           [href]="model.Github + '/issues/new'">
            Opened issues ({{ model.issues?.length || 0 }})
          </oex-wide-button>
          @for (issue of model.issues; track issue) {
            <div class="issue-item mp-link secondary">
              <img src="/assets/img/circle-dot.svg">
              <a [href]="issue.html_url" target="_blank" rel="noopener noreferrer">{{ issue.title }}</a>
            </div>
          }
        </div>
        <!-- Pull requests -->
        @if (currentTab === tabs.pullrequests) {
          <oex-pull-requests-list [model]="model">
          </oex-pull-requests-list>
        }
        <!-- Reviews -->
        @if (currentTab === tabs.reviews) {
          <oex-pkg-reviews-list
            [packageId]="model._id"
            [packageOwner]="isOwner()"
            [pkgOwnerName]="model.UserID.firstName || ''"
            [canReply]="!!model.Published"
            [canAdd]="model.UserID.individualKey !== auth.getUserKey()"
            [reviews]="model.Reviews || []"
          ></oex-pkg-reviews-list>
        }
        <!-- Rewards -->
        @if (currentTab === tabs.rewards) {
          <oex-reward-list
            [rewards]="model.Rewards || []"
          >
          </oex-reward-list>
        }
        <!-- Articles -->
        @if (currentTab === tabs.articles) {
          @for (dc of model.DCURLs; track trackByIndex($index, dc)) {
            <div class="article">
              <div class="article-header">
                <!-- Title -->
                <a [href]="dc.url" class="mp-link secondary article-url">{{ dc.title }}</a>
                <!-- Icons -->
                <div class="article-icons">
                  <img src="../../../assets/img/icon-like.svg">
                  <span>{{ dc.likes }}</span>
                  <img src="../../../assets/img/message.svg">
                  <span>{{ dc.comments }}</span>
                  <img src="../../../assets/img/eye.svg">
                  <span>{{ dc.views }}</span>
                </div>
                <div class="article-author">
                  {{ dc.authorName }}
                </div>
              </div>
            </div>
          }
        }
        <!-- Reports -->
        @if (currentTab === tabs.reports) {
          <oex-app-report-list
            [nws]="model.NameWithoutSpaces || ''"
          >
          </oex-app-report-list>
        }
        <!-- Dependencies -->
        @if (currentTab === tabs.deps) {
          <oex-deps-list
            [nws]="model.NameWithoutSpaces || ''"
          ></oex-deps-list>
        }
        <!-- Dependants -->
        @if (currentTab === tabs.depsnts) {
          <oex-depsnts-list
            [nws]="model.NameWithoutSpaces || ''"
          ></oex-depsnts-list>
        }
        <!-- Contest Vote-->
        @if (currentTab === tabs.contest) {
          <div class="contest-tab">
            <!-- Vote -->
            @if (this.model.participantId) {
              <div>
                <img src="../../../assets/img/info.svg">
                <span>This app is participating in the current contest</span>
              </div>
              @if (isOwner() || (!isOwner() && !canVote())) {
                <div>
                  <a class="btn-primary" [routerLink]="'/contest/' + getCurrentContestId()"
                     [fragment]="model.participantId">View contest</a>
                </div>
              }
              @if (!isOwner() && canVote()) {
                <div>
                  <oex-share-button [link]="'/contest/' + getCurrentContestId() + '#' + model.participantId">Share link
                    for
                    voting
                  </oex-share-button>
                  <a class="btn-primary" [routerLink]="'/contest/' + getCurrentContestId()"
                     [fragment]="model.participantId">Vote</a>
                </div>
              }
            }
            <!-- Apply for contest for owner if not sent -->
            @if (!canVote() && isContestActive() && model.sended !== 1) {
              <div>
                <img src="../../../assets/img/info.svg">
                <span>You may participate in the current contest</span>
              </div>
              <button class="btn-text btn-apply-contest" (click)="sendToContest()">
                Apply for contest<img src="../../../assets/img/arrow-right-orange.svg">
              </button>
            }
            <!-- Sent for contest for owner -->
            @if (!canVote() && isContestActive() && model.sended === 1 && !this.model.participantId) {
              <div>
                <img src="../../../assets/img/info.svg">
                <span>We are reviewing your application</span>
              </div>
            }
          </div>
        }
      </div>
      <!-- Info panel at right side -->
      <div class="mobile-info-panel-backdrop" [class.visible]="isInfoVisible"
           (click)="onPackageInfoBackdropClicked()"></div>
      <oex-pkg-info
        [class.inline]="isMobile"
        [class.visible]="isInfoVisible"
        [model]="model"
        [isPreview]="false"
        [isEdit]="false"
        [isNew]="false"
        [category]="getCategoryName()"
      ></oex-pkg-info>
    </div>
  </div>
}
