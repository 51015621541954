import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  HostListener,
  inject,
  input,
  OnDestroy,
  OnInit, PLATFORM_ID,
  viewChild
} from '@angular/core';
import {HttpService} from '../../services/httpService';
import {AuthService} from '../../services/authService';
import {ModalService} from '../../services/modal.service';
import {Router, RouterLink} from '@angular/router';
import {ConfigService} from '../../services/configService';
import {AppService} from '../../services/appService';
import {MediaListComponent} from './mediaList/media-list';
import {YOUTUBE_EMBED_URL} from './mediaList/media-preview';
import {PackageDetailsResolver} from './packageDetailsData';
import {OEXTab, TabsComponent} from '../../controls/tabs/tabs';
import {Subscription} from 'rxjs/internal/Subscription';
import {IVersionHistoryEntry, VersionHistoryComponent} from '../versionHistory/versionHistory';
import {TabsScreen} from '../../services/tabs-screen';
import {Status, TileBaseComponent} from '../../controls/tile/tile.base';
import {IPackageData} from '../../services/packageTypes';
import {PartnersDirectoryService} from '../../services/partnersDirectory';
import {ImageUploadComponent} from '../../controls/image-upload/image-upload';
import {BREADCRUMB_MAKE, BREADCRUMBS, BreadcrumbService} from '../../services/breadcrumb.service';
import {HeadlineComponent} from '../../controls/headline/headline';
import {DialogService} from '../../services/dialogService';
import {PackageInfoComponent} from './package-info/package-info';
import {DOCUMENT, isPlatformServer} from '@angular/common';
import {ShareButtonComponent} from '../../controls/share-button/share-button.component';
import {DependantsListComponent} from './dependants-list/dependants-list';
import {DependenciesListComponent} from './dependencies-list/dependencies-list';
import {AppReportList} from './app-report-list/app-report-list';
import {RewardListComponent} from './reward-list/reward-list.component';
import {ReviewsListComponent} from '../reviews/reviews-list.component';
import {PullRequestsListComponent} from './pull-requests-list/pull-requests-list.component';
import {WideButtonComponent} from '../../controls/wide-button/wide-button.component';
import {PackageDownloadsComponent} from '../package-downloads/packageDownloads';
import {CardComponent} from '../../controls/card/card';
import hljs from 'highlight.js';
import {SiteTrackerService} from '../../services/site-tracker.service';
import {ROUTE_MARKETPLACE} from '../../services/global-types';
import {GetItNowDialogComponent} from '../../dialogs/get-it-dialog/get-it-now-dialog.component';

// OneTrust
declare const OnetrustActiveGroups: any;
declare const OneTrust: any;

const PkgTabs: { [key: string]: OEXTab } = {
  details: {id: 'details', text: 'Details'},
  history: {id: 'history', text: 'Releases'},
  reviews: {id: 'reviews', text: 'Reviews'},
  rewards: {id: 'rewards', text: 'Awards'},
  screenshots: {id: 'screenshots', text: 'Screenshots'},
  analytics: {id: 'analytics', text: 'Analytics'},
  issues: {id: 'issues', text: 'Issues'},
  pullrequests: {id: 'pullrequests', text: 'Pull requests'},
  // provider: {id: 'provider', text: 'Provider'},
  articles: {id: 'articles', text: 'Articles'},
  videos: {id: 'videos', text: 'Videos'},
  reports: {id: 'reports', text: 'Reports'},
  deps: {id: 'deps', text: 'Dependencies'},
  depsnts: {id: 'depsnts', text: 'Dependants'},
  contest: {id: 'contest', text: 'Contest', dot: true}
};

@Component({
  selector: 'oex-pkg-screen',
  templateUrl: 'packageDetails.html',
  imports: [
    PackageInfoComponent,
    RouterLink,
    ShareButtonComponent,
    DependantsListComponent,
    DependenciesListComponent,
    AppReportList,
    RewardListComponent,
    ReviewsListComponent,
    PullRequestsListComponent,
    WideButtonComponent,
    PackageDownloadsComponent,
    VersionHistoryComponent,
    MediaListComponent,
    CardComponent,
    TabsComponent,
    HeadlineComponent
  ],
  styleUrls: ['../../controls/tile/status.scss', 'packageDetails.scss'],
  host: {
    'class': 'mp-pkg-screen'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageDetailsScreenComponent extends TabsScreen implements OnInit, AfterViewInit, OnDestroy {
  auth = inject(AuthService);
  st = inject(SiteTrackerService);
  pd = inject(PartnersDirectoryService);
  readonly package = input<IPackageData>();
  // @ViewChild('uploader') uploader: ElementRef;
  readonly imgUploader = viewChild.required<ImageUploadComponent>('imgUploader');
  readonly headline = viewChild.required<HeadlineComponent>('headline');
  isSaving = false;
  subDataChanged?: Subscription;
  isUploading = false;
  getStatus: Function;
  TILE_STATUS = Status;
  currentTab = PkgTabs.details;
  override tabs = PkgTabs;
  model!: IPackageData;
  isInfoVisible = false;
  protected readonly parseInt = parseInt;
  private http = inject(HttpService);
  private modal = inject(ModalService);
  private router = inject(Router);
  private ds = inject(DialogService);
  private brs = inject(BreadcrumbService);
  private doc = inject<Document>(DOCUMENT);
  private readonly platformId = inject<Object>(PLATFORM_ID);
  private onLogin$: Subscription;

  private readonly descMD = viewChild<ElementRef>('descriptionMD');
  _ = effect(() => {
    const ref = this.descMD();
    if (ref) {
      this.updateDescriptionLinks(ref);
    }
  });

  /**
   * Constructor
   */
  constructor() {
    super();
    this.getStatus = TileBaseComponent.getTileStatus.bind(this);

    // Detect changes after login
    this.onLogin$ = this.auth.onLogin.subscribe(l => {
      this.cd.detectChanges();
    });
  }

  get isMobile(): boolean {
    if (ConfigService.isServer) {
      return false;
    }
    return window.screen.availWidth < 736;
  }

  get isTablet(): boolean {
    if (ConfigService.isServer) {
      return false;
    }
    return window.screen.availWidth >= 736 && window.screen.availWidth < 1170;
  }

  trackByIndex = (index: number, r: any) => index;

  /*@HostBinding('class.in-portal')
  get isInPortal() {
    return this.st.isPortal;
  }*/
  override ngOnInit() {
    super.ngOnInit();

    const p = this.package();
    if (p) {
      this.onDataReceived(p);
    } else {
      this.subDataChanged = this.route.data.subscribe(d => {
        this.onDataReceived(d.model);
      });
    }
  }

  ngAfterViewInit() {
    // Track analytics view, but don't wait for it and ignore any errors
    if (!ConfigService.isServer) {
      this.highlightCodeBlocks();
      void this.analyticsTrackView().catch(() => {
      });

      // Scroll to anchor
      const anchor = location.hash;
      this.scrollToAnchor(anchor);
    }
  }

  highlightCodeBlocks() {
    if (!hljs) {
      return;
    }
    // Select all code blocks with a "language" attribute
    const codeBlocks = this.doc.querySelectorAll<HTMLElement>(`code[class^='language-']`);

    // Loop through code blocks and highlight each one
    codeBlocks.forEach(block => {
      const languageClass = block.className;
      const language = languageClass.replace('language-', '');
      hljs.highlightBlock(block);
    });
  }

  @HostListener('window:hashchange', ['$event'])
  hashChangeHandler(e) {
    if (ConfigService.isServer) {
      return;
    }
    this.scrollToAnchor(window.location.hash);
  }

  scrollToAnchor(anchor: string) {
    if (ConfigService.isServer) {
      return;
    }
    if (anchor) {
      const el = this.doc.querySelector(`.anchor[href="${anchor.toLowerCase()}"]`);
      if (el) {
        const y = el.getBoundingClientRect().top + window.pageYOffset - 30;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }
  }

  isContestActive(): boolean {
    return ConfigService.isContestActive();
  }

  /**
   * Returns category name
   * @returns {string}
   */
  getCategoryName(): string {
    if (!this.model || !this.model.CategoryID || !this.model.CategoryID.Name) {
      return '';
    }
    return AppService.encodeText(this.model.CategoryID.Name);
  }

  /**
   * Increases downloads count
   */
  incDownloads() {
    this.http.request('/mpapi/packages/incDownloads', 'post', {id: this.model._id})
      .then(r => {
        if (r && r.count) {
          this.model.DownloadsCount = r.count;
        }
      });
  }

  /**
   * Returns package status text
   * @returns {string}
   */
  public getStatusText() {
    return TileBaseComponent.getStatusText(this.model);
  }

  /**
   * Returns tile status
   * @returns {TILE_STATUS}
   */
  public getTileStatus() {
    return TileBaseComponent.getTileStatus(this.model);
  }

  /**
   * Downloads package
   */
  download(e: MouseEvent) {
    e.stopPropagation();
    if (this.st.isMarketplace) {
      this.showGetItNowDialog();
      return;
    }


    this.incDownloads();
    let url = this.model.ProductURL;
    if (!url.toLowerCase().startsWith('http')) {
      url = 'http://' + url;
    }
    window.open(url);
  }

  /**
   * Opens discuss tab
   */
  discuss() {
    if (!this.model.DCURLs[0]) {
      return;
    }
    window.open(this.model.DCURLs[0].url, '_blank');
  }

  /**
   * Checks if user can unpublish package
   * @returns {boolean} True if can
   */

  /*canUnpublish(): boolean {
    if (this.model.Published !== true) {
      return false;
    }
    if (this.auth.isAdmin) {
      return true;
    }
    if (!this.model.UserID) {
      return false;
    }
    return this.model.UserID?.login === this.auth.getUser();
  }*/

  /**
   * Shows modal dialog with error message
   * @param {Exception} e Exception
   */
  showError(e: any) {
    try {
      const txt = e.error.summary.replace(/\?/g, '');
      this.modal.show(txt);
    } catch (er) {
      console.error(`Can't save package: `, e);
    }
  }

  /**
   * Sends package to contest
   */
  sendToContest() {
    this.ds.sendToContest(this.model);
  }

  /**
   * Checks if user can publish
   */
  canPublish(): boolean {
    return (!this.model.Published && !this.model.NeedApprove);
  }

  /**
   * Checks is user is owner of package
   */
  isOwner(): boolean {
    return this.model.UserID?.login === this.auth.getUser();
  }

  /**
   * Checks if user can delete this package
   * Package can be deleted only in "draft" status
   * Admin can delete all packages
   * User can delete only his own
   */
  canDelete(): boolean {
    if (this.auth.isAdmin) {
      return true;
    }
    return this.model.UserID?.login === this.auth.getUser();
  }

  /**
   * Checks if user can edit package
   */
  canEdit(): boolean {
    if (this.model?._id === 'new') {
      return false;
    }
    if (this.auth.isAdmin) {
      return true;
    }
    return this.model.UserID?.login === this.auth.getUser();
  }

  /**
   * Can user view additional info about package or not
   */
  canViewInfo(): boolean {
    if (this.auth.isAdmin) {
      return true;
    }
    return this.model.UserID?.login === this.auth.getUser();
  }

  /**
   * Returns safe video url
   */
  getVideoUrl(idx: number): string {
    const url = this.model.VideoURLs[idx]?.url;
    if (!url) {
      return '';
    }
    return YOUTUBE_EMBED_URL + MediaListComponent.getVideoKey(url);
    // return this.san.bypassSecurityTrustResourceUrl(YOUTUBE_EMBED_URL + MediaListComponent.getVideoKey(url));
  }

  /**
   * Adds or removes star for package
   */
  toggleStar() {
    this.http.request('/mpapi/packages/star/toggle', 'post', {id: this.model._id})
      .then((r: { stars: number, status: number }) => {
        this.model.Stars = r.stars;
        this.model.IsStar = r.status;
        this.headline().detectChanges();
      });
  }

  /**
   * Adds or removes watcher for package
   */
  toggleWatch() {
    this.http.request('/mpapi/packages/follow/toggle', 'post', {id: this.model._id})
      .then((r: { follows: number, status: number }) => {
        this.model.Follows = r.follows;
        this.model.IsFollow = r.status;
        this.headline().detectChanges();
      });
  }

  /**
   * Returns available actions for package
   */
  /* getActions(): string[] {
     const res = [];
     if (this.canEdit()) {
       res.push('Edit');
     }
     /!*if (!this.auth.isIntersystems() && this.isContestActive() && this.model.Published === true && this.model.sended !== 1) {
       res.push('Apply for Contest');
     }*!/
     /!*if (this.canEdit() && (this.model.Published !== true)) {
       res.push('Upload image');
     }*!/
     /!*if (this.canPublish()) {
       res.push('Send for approval');
     }*!/
    /!* if (this.canUnpublish()) {
       res.push('Unpublish');
     }*!/
     /!*if (this.canDelete()) {
       res.push('Delete');
     }*!/
     /!*if (this.model?.FullDescriptionGitCheck === 1) {
       res.push('Update GitHub description');
     }*!/
     return res;
   }*/

  /**
   * Performs action
   * @param action
   */

  /* onAction(action: IMenuButtonAction) {
     switch (action.item.toLowerCase()) {
       case 'edit':
         void this.router.navigateByUrl('/portal/package/' + this.model.NameWithoutSpaces);
         break;
      /!* case 'apply for contest':
         this.sendToContest();
         break;*!/
       /!*case 'upload image':
         this.showUploadDialog();
         break;*!/
       /!*case 'send for approval':
         this.askForReleaseNotes();
         break;*!/
      /!* case 'unpublish':
         this.askForUnpublish();
         break;*!/
      /!* case 'delete':
         this.askForDeletion();
         break;*!/
       /!*case 'update github description':
         this.updateGithubDesc();
         break;*!/
     }
   }*/

  /**
   * Requests issues
   */
  requestIssues() {
    // Request issues only on client. Issues is not on SSR for performance
    if (ConfigService.isServer) {
      return;
    }

    // Check github url
    const url = this.model.Github;
    if (!url) {
      return;
    }

    // Request issues via gitgub API
    this.http.request(PackageDetailsResolver.getGithubAPIUrl(url) + '/issues', 'get', undefined, undefined, true)
      .then(d => {
        // Show only open issues
        this.model.issues = d.filter(i => !i.pull_request && i.state === 'open');
      })
      .catch((e) => {
        // Do nothing, it's ok if there is something wrong with issues
      });
  }

  /**
   * Returns tabs for package details screen
   */
  override getTabs(): OEXTab[] {
    const tabs = [PkgTabs.details];
    if (!this.model.ParentSolution && this.model.ReleasesCount) {
      PkgTabs.history.count = this.model?.ReleasesCount;
      tabs.push(PkgTabs.history);
    }
    PkgTabs.reviews.count = this.model.Reviews?.length;
    tabs.push(PkgTabs.reviews);

    /*if ((this.model && this.model.Screenshots && this.model.Screenshots.length) || this.isOwner()) {
      tabs.push(PkgTabs.screenshots);
    }*/
    if (this.model?.Rewards?.length) {
      PkgTabs.rewards.count = this.model?.Rewards?.length;
      tabs.push(PkgTabs.rewards);
    }
    if (this.canViewInfo()) {
      tabs.push(PkgTabs.analytics);
    }
    if (this.model && this.model.Github) {
      tabs.push(PkgTabs.issues);
    }
    if (this.model?.PullRequests?.length) {
      PkgTabs.pullrequests.count = this.model?.PullRequests?.length;
      tabs.push(PkgTabs.pullrequests);
    }
    /*if (this.model?.PublisherID?._id) {
      tabs.push(PkgTabs.provider);
    }*/
    if (this.model?.VideoURLs?.length && this.model?.VideoURLs.filter(u => !!u?.url).length) {
      PkgTabs.videos.count = this.model?.VideoURLs.filter(u => !!u?.url).length;
      tabs.push(PkgTabs.videos);
    }
    if (this.model?.DCURLs?.length && this.model?.DCURLs.filter(u => !!u?.url).length) {
      PkgTabs.articles.count = this.model?.DCURLs.filter(u => !!u?.url).length;
      tabs.push(PkgTabs.articles);
    }
    if (!this.pd.enabled && this.model.ReportsCount && (this.isOwner() || this.auth.isAdmin || this.auth.isModerator)) {
      PkgTabs.reports.count = this.model?.ReportsCount;
      tabs.push(PkgTabs.reports);
    }
    if (this.model?.DependenciesCount) {
      PkgTabs.deps.count = this.model?.DependenciesCount;
      tabs.push(PkgTabs.deps);
    }
    if (this.model?.DependantsCount) {
      PkgTabs.depsnts.count = this.model?.DependantsCount;
      tabs.push(PkgTabs.depsnts);
    }
    if (
      !this.pd.enabled && (
        // For any package that on contest
        this.canVote() ||
        // If registration period and you owner
        (this.isContestActive() && this.isOwner())
      )) {
      tabs.push(PkgTabs.contest);
    }
    return tabs;
  }

  /**
   * Destroy event
   */
  override ngOnDestroy() {
    this.onLogin$.unsubscribe();
    if (this.subDataChanged) {
      this.subDataChanged.unsubscribe();
    }
  }

  onVersionsChanged(versions: IVersionHistoryEntry[]) {
    this.model.Versions = [...versions];
    this.resortVersions();
  }

  override onQueryParamsChanged(params: any) {
    super.onQueryParamsChanged(params);
    // Check permissions for analytics and move to details if there is no access
    if (this.currentTab === PkgTabs.analytics && !this.canViewInfo()) {
      this.currentTab = PkgTabs.details;
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {}
        });
    }
  }

  getDownloadButtonText(): string {
    return ConfigService.getDownloadButtonText(this.model);
  }

  // TODO: move to one place (package info code duplication)
  getCurrentContestId() {
    return ConfigService.contest.id;
  }

  canVote() {
    if (!this.model?.participantId) {
      return false;
    }
    /* if (this.model && this.isOwner() && this.model.sended === 1) {
       return false;
     }*/
    return ConfigService.contest.voting === 1 && !ConfigService.isContestEnded();
  }

  /**
   * Opens Online Demo tab
   */
  onlineDemo() {
    if (!this.model.DemoURL) {
      return;
    }

    window.open(this.model.DemoURL, '_blank', 'noopener,noreferrer');
  }

  // TODO: pre encode tags in data resolver
  encodeTag(tag: string): string {
    if (!tag) {
      return '';
    }
    tag = tag.trim();
    return AppService.encodeText(tag);
  }

  onShowInfoClicked(e: MouseEvent) {
    this.isInfoVisible = true;
  }

  /* private fixTUIEditorScroll() {
     setTimeout(() => {
       this.editorDesc.editor.moveCursorToStart();
     });
   }*/

  onPackageInfoBackdropClicked() {
    this.isInfoVisible = false;
  }

  private onDataReceived(m: IPackageData) {
    this.model = m;

    const byCompany = this.route.snapshot.queryParamMap.get('byCompany');
    if (byCompany) {
      this.model.ByCompany = byCompany;
    }

    // For invalid packages(no access, not exists, etc.) - navigate home
    if (this.model.invalid) {
      void this.router.navigateByUrl('/' + (this.st.isMarketplace ? ROUTE_MARKETPLACE : ''));
      return;
    }

    // Change breadcrumbs
    if (this.router.url.split('/')[1] === 'portal') {
      this.brs.setBreadcrumbs([BREADCRUMBS.BackToEditing], false);
    } else {
      this.brs.setBreadcrumbs([BREADCRUMBS.Applications, BREADCRUMB_MAKE(this.model.Name)]);
    }

    // Issues is not SSR
    this.requestIssues();
    this.cd.detectChanges();
  }

  private analyticsTrackView(): Promise<any> {
    return this.http.requestData(`/mpapi/analytics/view`, 'post', {sid: this.model._id});
  }

  /*showUploadDialog() {
    this.uploader.nativeElement.click();
  }*/

  /*private updateGithubDesc() {
    this.http.request('/mpapi/packages/update_desc', 'post', {nws: this.model?.NameWithoutSpaces})
      .then(d => {
        if (d.FullDescriptionMd) {
          const result = OEXMarkdown.parseGit(d.FullDescriptionMd);
          this.model.FullDescriptionMd = result;
        }
        this.modal.show('Description has been updated successfully');
      })
      .catch(e => {
        this.showError(e);
        return false;
      });
  }*/

  /**
   * Compares string versions for sort function
   * @param a
   * @param b
   */
  private compareVersions(a: string, b: string): number {
    const v1 = a.split('.');
    const v2 = b.split('.');


    const len = Math.min(v1.length, v2.length);
    for (let i = 0; i < len; ++i) {
      if (parseInt(v1[i], 10) < parseInt(v2[i], 10)) {
        return 1;
      } else if (parseInt(v1[i], 10) > parseInt(v2[i], 10)) {
        return -1;
      }
    }

    if (v1.length < v2.length) {
      return 1;
    } else if (v1.length > v2.length) {
      return -1;
    }

    return 0;
  }

  /**
   * Resorts releases based on published state and version
   */
  private resortVersions() {
    if (!this.model?.Versions || this.model?.Versions?.length === 0) {
      return;
    }

    this.model.Versions = this.model.Versions.sort((v1, v2) => {
      if (v1.published === v2.published) {
        return this.compareVersions(v1.ver, v2.ver);
      } else {
        return +v1.published > +v2.published ? 1 : -1;
      }
    });
  }

  private updateDescriptionLinks(ref: ElementRef) {
    if (ConfigService.isServer) {
      return;
    }
    const el = ref.nativeElement;
    const links = el.querySelectorAll('a');
    links.forEach(l => {
      if (l.href === location.origin + '/' + l.hash && !l.classList.contains('anchor')) {
        l.href = '/package/' + this.model.NameWithoutSpaces + l.hash;
      }
    });
  }

  private showGetItNowDialog() {
    if (!this.auth.isLogged) {
      this.modal.showUnauthDialog();
      return;
    }
    this.modal.show({
      cancel: true,
      compModel: {
        component: GetItNowDialogComponent,
        props: {
          solId: this.model._id,
          companyName: this.model.PublisherID?.Name ?? '',
          onSave: () => {
            this.model.inquirySent = 1;
            const headline = this.headline();
            if (headline) {
              headline.update();
            }
          }
        }
      },
      buttons: []
    });
  }

  protected onTabChanged(tab: OEXTab | undefined) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (tab?.id !== 'videos') {
      return;
    }
    this.refreshOneTrustIframes()
  }

  private refreshOneTrustIframes() {
    // C0004 - Group for iframes consent
    if (!window['OneTrust'] || !window['OnetrustActiveGroups'] || !window['OnetrustActiveGroups'].includes(',C0004,')) {
      return;
    }

    try {
      setTimeout(() => {
        window['OneTrust'].UpdateConsent("Category", "C0004:1");
      });
    } catch {}
  }
}

